import "./bootstrap";
import "../css/app.css";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";
import { createPinia } from "pinia";

import * as Sentry from "@sentry/vue";
import axios from "@/helpers/axios";

const appName = import.meta.env.VITE_APP_NAME || "Laravel";

const pinia = createPinia();

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        let app = createApp({ render: () => h(App, props) });

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
        });

        app.config.globalProperties.$axios = axios;

        pinia.use(({ store }) => {
            store.$axios = app.config.globalProperties.$axios;
        });

        app.use(plugin).use(pinia).use(ZiggyVue, Ziggy).mount(el);

        return app;
    },
    progress: {
        color: "#4B5563",
    },
});
